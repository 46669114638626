<div class="sb animate__animated animate__fadeInRight" [ngStyle]="{'right': showSocialbar === true ? '0' : '-3.125rem'}" *ngIf="show === true">
    <div class="sb-control text-white p-2 bg-dark text-white" (click)="toggleSocialBar()">
            <i class="fas fa-angle-right fa-1x" *ngIf="showSocialbar === true"></i>
            <i class="fas fa-angle-left fa-1x" *ngIf="showSocialbar === false"></i>
    </div>
    <ul class="ct-socials bg-dark">
        <li class="twitter" *ngIf="twitterUrl">
            <a class="text-decoration-none" [attr.href]="twitterUrl | safe: 'url'" target="_blank"><i
                    class="fab fa-twitter"></i></a>
        </li>
        <li class="facebook" *ngIf="facebookUrl">
            <a class="text-decoration-none" href="{{facebookUrl}}" target="_blank"><i class="fab fa-facebook"></i></a>
        </li>
        <li class="whatsapp" *ngIf="whatsappUrl">
            <a class="text-decoration-none" href="https://wa.me/{{whatsappUrl}}" target="_blank"><i
                    class="fab fa-whatsapp"></i></a>
        </li>
        <li class="instagram" *ngIf="instagramUrl">
            <a class="text-decoration-none" href="{{instagramUrl}}" target="_blank"><i class="fab fa-instagram"></i></a>
        </li>
        <li class="twitch" *ngIf="twitchUrl">
            <a class="text-decoration-none" href="{{twitchUrl}}" target="_blank"><i class="fab fa-twitch"></i></a>
        </li>
        <li class="etsy" *ngIf="etsyUrl">
            <a class="text-decoration-none" href="{{etsyUrl}}" target="_blank"><i class="fab fa-etsy"></i></a>
        </li>
    </ul>
</div>
