import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ImagePageAttributes} from '../../_models/image-page-attributes';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {GalleryComponent, GalleryItem, ImageItem} from '@ngx-gallery/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() attributes: ImagePageAttributes;
    private subConfig: Subscription = new Subscription();
    images: Array<GalleryItem> = [];
    @ViewChild(GalleryComponent) gallery: GalleryComponent;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.subConfig = this.http.get('assets/config.json').subscribe((conf: any) => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < conf.customPages.length; i++) {
                if (conf.customPages[i].navConfig.pagetype === 'gallery') {
                    for (let j = 0; j < conf.customPages[i].attributes.images.length; j++) {
                        const img = new ImageItem({
                            src: conf.customPages[i].attributes.images[j].path,
                            thumb: this.getThumbnailPath(conf.customPages[i].attributes.images[j].path)
                        });
                        this.images.push(img);
                    }
                    break;
                }
            }

            this.gallery.load(this.images);
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subConfig.unsubscribe();
    }

    /**
     * get the thumbnails path from full Url
     * @param path  of the image
     */
    getThumbnailPath(path: string) {
        const fileName = path.split('/').pop();
        const lastSlash = path.lastIndexOf('/');
        const beforeLastSlash = path.substring(0, lastSlash);
        return beforeLastSlash + '/thumbnails/' + fileName;
    }
}
