import {Component, OnDestroy, OnInit} from '@angular/core';
import {Company} from './_models/company';
import {InfobarService} from './_services/infobar.service';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {NgwWowService} from 'ngx-wow';
import {filter} from 'rxjs/operators';
import {OwnerInfoService} from './_services/owner-info.service';
import {SectionService} from './_services/section.service';
import {Section} from './_models/section';
import {NavLinkConfig} from './_models/nav-link-config';
import {ComponentService} from './_services/component.service';
import * as config from '../assets/config.json';
import {ThemeService} from './_services/theme.service';
import {defaultTheme} from './_themes/default-theme';
import {Title} from '@angular/platform-browser';
import {PageService} from './_services/page.service';
import {Page} from './_models/page';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private wowSubscription: Subscription = new Subscription();
    private routerSubscription: Subscription = new Subscription();

    title = 'presentCMS - Demo Page';
    company: Company;
    showSocialbar: boolean;
    showConsent = true;

    constructor(private infobarService: InfobarService, private router: Router,
                private wowService: NgwWowService, private ownerInfoService: OwnerInfoService,
                private sectionService: SectionService, private componentService: ComponentService,
                private themeService: ThemeService, private titleService: Title, private pageService: PageService) {
        this.company = this.ownerInfoService.company;

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            if (event.url !== '/') {
                window.scrollTo(0, 0); // scroll top on route change if other page

            } else {
                this.infobarService.setCurrentRoute(event.url);
            }

            // Reload WoW animations when done navigating to page,
            // but you are free to call it whenever/wherever you like
            this.infobarService.setCurrentRoute(event.url.substring(1));
            this.wowService.init();
        });
    }

    ngOnDestroy(): void {
        this.wowSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    setTheme() {
        this.themeService.setTheme('default');
    }

    ngOnInit(): void {
        // check show cookie consent
        if (localStorage.getItem('acceptCookies') === 'true') {
            this.showConsent = true;
        }

        this.title = config.default.pageName;
        this.titleService.setTitle(this.title);

        // set company information
        this.ownerInfoService.setCompany(config.default.company);
        this.ownerInfoService.setAddress(config.default.address);
        this.ownerInfoService.setPerson(config.default.person);
        this.ownerInfoService.showNameInInforbar = config.default.showNameInInforbar;
        this.ownerInfoService.showLogoInInforbar = config.default.showLogoInInforbar;
        this.ownerInfoService.logo = config.default.logo;
        this.showSocialbar = config.default.showSocialBar;

        // set theme
        for (let i = 0; i < Object.keys(config.default.style).length; i++) {
            // tslint:disable-next-line:max-line-length
            defaultTheme.properties[`--${Object.keys(config.default.style)[i]}`] = `${this.hex2rgb(Object.values(config.default.style)[i])}`;
        }

        this.setTheme();

        // set attributes
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < config.default.sections.length; i++) {
            const section = config.default.sections[i];
            const s = new Section();
            s.component = this.componentService.getComponent(section.name);
            s.attributes = this.componentService.getComponentAttributes(section.name);

            for (const key in section.attributes) {
                if (section.attributes.hasOwnProperty(key)) {
                    // if company, use companyService instead of json
                    if (key === 'company') {
                        s.attributes[key] = this.ownerInfoService.getCompany();
                    } else if (key === 'contactPerson') {
                        s.attributes[key] = this.ownerInfoService.getPerson();
                    } else if (key === 'companyAddress') {
                        s.attributes[key] = this.ownerInfoService.getAddress();
                    } else {
                        s.attributes[key] = section.attributes[key];
                    }
                }
            }

            s.navConfig = new NavLinkConfig(section.navConfig.label,
                section.navConfig.id, section.navConfig.jumpTo, section.navConfig.pagetype);
            s.attributes.navConfig = s.navConfig;
            this.sectionService.addSection(s);
        }

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < config.default.customPages.length; i++) {
            const page = config.default.customPages[i];
            const p = new Page();
            p.component = this.pageService.getComponent(page.route);
            p.attributes = this.pageService.getComponentAttributes(page.route);

            for (const key in page.attributes) {
                if (page.attributes.hasOwnProperty(key)) {
                    p.attributes[key] = page.attributes[key];
                }
            }

            p.navConfig = new NavLinkConfig(page.navConfig.label, page.navConfig.id, page.navConfig.jumpTo, page.navConfig.pagetype);
            p.attributes.navConfig = p.navConfig;

            this.pageService.addPage(p);
        }
    }

    /**
     * convert hex to rgb
     * @param colour: colour change to rgb
     */
    hex2rgb(colour) {
        // tslint:disable-next-line:one-variable-per-declaration
        let r, g, b;
        if (colour.charAt(0) === '#') {
            colour = colour.substr(1);
        }
        if (colour.length === 3) {
            colour = colour.substr(0, 1) + colour.substr(0, 1) + colour.substr(1, 2)
                + colour.substr(1, 2) + colour.substr(2, 3) + colour.substr(2, 3);
        }
        r = colour.charAt(0) + '' + colour.charAt(1);
        g = colour.charAt(2) + '' + colour.charAt(3);
        b = colour.charAt(4) + '' + colour.charAt(5);
        r = parseInt(r, 16);
        g = parseInt(g, 16);
        b = parseInt(b, 16);
        return r + ',' + g + ',' + b;
    }​
}
