<div class="consent p-3 bg-dark text-white shadow animate__animated animate__fadeInRight"
     [ngClass]="{'animate__fadeOutRight': didAgree === true}">
    <div class="consent-text mb-2">Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als
        Benutzer finden Sie in unserer <a routerLink="/page/impressum" href="javascript:">Datenschutzerklärung</a>. Klicken
        Sie auf „Ich stimme zu“, um
        Cookies zu akzeptieren.
    </div>

    <button class="btn btn-primary" (click)="doAgree()">Ich stimme zu</button>
</div>
