<div class="w-100 h-auto row p-0 m-0 d-flex justify-content-center bg-dark text-white">
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4 border-right border-secondary">
        <div class="h-25">
            <h4>Unser Standort</h4>
        </div>
        <div class="h-75">
            <ul class="list-unstyled w-100">
                <li>{{attributes?.company?.name}}</li>
                <li>{{attributes?.company?.address?.street + ' ' + attributes?.company?.address?.houseNr + ', ' + attributes?.company?.address?.plz + ' ' + attributes?.company?.address?.city}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4 border-right border-secondary">
        <div class="h-25"><h4>Kontakt</h4></div>
        <div class="h-75">
            <ul class="list-unstyled w-100">
                <li>Tel.: <a [attr.href]="'tel:' + attributes?.company?.phone || attributes?.company?.owner?.phone"> {{attributes?.company?.phone || attributes?.company?.owner?.phone}}</a></li>
                <li *ngIf="attributes?.company?.owner?.mobile">Mobil: <a [attr.href]="'tel: ' + attributes?.company?.owner?.mobile"> {{attributes?.company?.owner?.mobile}} </a></li>
                <li>Email: <a [attr.href]="'mailto:' + attributes?.company?.email || attributes?.company?.owner?.email"> {{attributes?.company?.email || attributes?.company?.owner?.email}}</a></li>
                <li *ngIf="attributes?.company?.fax">Fax: {{attributes?.company?.fax}}</li>
            </ul>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center p-4 d-flex align-items-center">
        <ul class="list-unstyled w-100">
            <li>Copyright &copy; 2020 {{attributes?.company?.name}}</li>
            <li><a routerLink="/page/impressum">Impressum</a></li>
        </ul>
    </div>
</div>
