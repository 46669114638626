import {NavLinkConfig} from './nav-link-config';

export class LandingPageSectionAttributes {
    navConfig: NavLinkConfig;
    bigTeaser = 'PresentCMS';
    smallTeaser = 'Eine einfache Webseite...';
    buttonLabel = 'External URL';
    buttonUrl = '#';
    showButton = true;
    bgUrl: string;
    bigTeaserAnimation: string;
    smallTeaserAnimation: string;
    textColor: string;
}
